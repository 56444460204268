/* imports the universal font for the site */
@import url('https://fonts.googleapis.com/css2?family=Open+Sans&family=Work+Sans&display=swap');

/* sets the background color for the whole site */
body {
	/* background-color: #A2B6E7; */
	background-color: rgba(244, 244, 244, 1);
	/* color: #545F66; */
}

/* sets the width of the scrollbar */
body::-webkit-scrollbar { 
	width: 20px;               /* width of the entire scrollbar */
}

body::-webkit-scrollbar-track {
	background: #edeeee;
	width: 20px;
}

body::-webkit-scrollbar-thumb {
	background: #77777761;
	width: 20px;
}

body::-webkit-scrollbar-button {
	background: #212121ea;
	width: 20px;
	
}